<template>
  <v-container>
    <v-form v-model="formIsValid" ref="form">
      <v-row class="text-center">
        <v-col>
          <h1>
            Grönsakshuset kundanmälan
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!sent">
          <v-text-field v-model="departmentName" label="Enhetsnamn" :rules="[rules.required]" />
          <v-text-field v-model="businessName" label="Bolagsnamn" :rules="[rules.required]" />
          <v-text-field v-model="organisationNumber" label="Org.nummer"
              :rules="[rules.required]" />
          
          <strong>Leveransadress</strong>
          <v-text-field v-model="deliveryAddress" label="Gatuadress" :rules="[rules.required]" />
          <v-text-field v-model="deliveryPostal" label="Postnummer" :rules="[rules.required]" />
          <v-text-field v-model="deliveryPlace" label="Ort" :rules="[rules.required]" />
          <v-text-field v-model="deliveryPlaceInfo" label="Info om leveransplats" />
          <v-text-field v-model="deliveryAddressNote" label="Not. om leveransadress" />

          <strong>Faktureringsadress</strong>
          <v-text-field v-model="invoiceAddress" label="Postadress/Box" :rules="[rules.required]" />
          <v-text-field v-model="invoicePostal" label="Postnummer" :rules="[rules.required]" />
          <v-text-field v-model="invoicePlace" label="Ort" :rules="[rules.required]" />
          <v-text-field v-model="invoiceEmail" label="E-postadress för faktura" :rules="[rules.required, rules.email]" />
          <v-text-field v-model="invoiceReference" label="Referens faktura" />

          <strong>Kontaktuppgifter</strong>
          <v-text-field v-model="departmentContactName" label="Namn" :rules="[rules.required]" />
          <v-text-field v-model="departmentContactPhone" label="Telefonnummer" :rules="[rules.required, rules.phone]" />
          <v-text-field v-model="departmentContactEmail" label="E-postadress" :rules="[rules.required, rules.email]" />

          <strong>Önskad leveransdag</strong>
          <v-text-field v-model="desiredDeliveryDay" label="Önskad dag"/>
          
          <v-checkbox v-model="consent"
            label="Jag godkänner att denna webbplats lagrar min inlämnade information enligt beskrivningen i cforetaget.se/integritetspolicy så att de kan svara på min förfrågan."
            :rules="[rules.requiredBox]"></v-checkbox>
          <v-btn @click="save" color="blue" class="white--text" :disabled="sending">skicka</v-btn>
          <v-progress-circular class="ml-3" indeterminate v-if="sending" color="blue" size="25" />
          <p class="mt-3 red--text" v-if="error">
            Tekniskt fel uppstod, ansökan gick inte att skicka. Försök igen om
            en stund.
          </p>
        </v-col>
        <v-col v-else>
          <p>
            Tack för din ansökan!
          </p>
          <a href="https://cmartsupport.se/" style="text-decoration:none">Gå tillbaka till hemsidan</a>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { createVegetableHouse } from "../graphql";

export default {
  name: "VegetableHouse",
  data: () => ({
    departmentName: null,
    businessName: null,
    organisationNumber: null,
    deliveryAddress: null,
    deliveryAddressNote: null,
    deliveryPostal: null,
    deliveryPlace: null,
    deliveryPlaceInfo: null,
    invoiceAddress: null,
    invoicePostal: null,
    invoicePlace: null,
    invoiceEmail: null,
    invoiceReference: null,
    departmentContactName: null,
    departmentContactPhone: null,
    departmentContactEmail: null,
    desiredDeliveryDay: null,
    consent: false,
    sent: false,
    sending: false,
    error: false,
    formIsValid: false,
    rules: {
      required: value => !!value || "Måste anges",
      requiredBox: value => !!value || "Måste kryssas för",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Felaktigt format.";
      },
      phone: value => {
        const pattern = /^[0-9- ]+$/;
        return pattern.test(value) || "Använd endast siffror och bindestreck.";
      }
    }
  }),
  methods: {
    save() {
      this.error = false;
      if (!this.$refs.form.validate()) {
        console.log("form is invalid, aborting");
        return;
      }
      this.sending = true;
      console.log("saving...");
      createVegetableHouse({
        departmentName: this.departmentName,
        businessName: this.businessName,
        organisationNumber: this.organisationNumber,
        deliveryAddress: this.deliveryAddress,
        deliveryAddressNote: this.deliveryAddressNote,
        deliveryPostal: this.deliveryPostal,
        deliveryPlace: this.deliveryPlace,
        deliveryPlaceInfo: this.deliveryPlaceInfo,
        invoiceAddress: this.invoiceAddress,
        invoicePostal: this.invoicePostal,
        invoicePlace: this.invoicePlace,
        invoiceEmail: this.invoiceEmail,
        invoiceReference: this.invoiceReference,
        departmentContactName: this.departmentContactName,
        departmentContactPhone: this.departmentContactPhone,
        departmentContactEmail: this.departmentContactEmail,
        desiredDeliveryDay: this.desiredDeliveryDay,
      })
        .then(data => {
          console.log("data from mutation", data);
          this.sending = false;
          this.sent = true;
        })
        .catch(err => {
          console.log("error saving application", err);
          this.sending = false;
          this.error = true;
        });
    }
  }
};
</script>