import { API } from "aws-amplify"

export const createMutation = input => API.graphql({
  query:
    `mutation create($input: CreateMemberApplicationInput!) {
      createMemberApplication(input: $input) {
        id
        preschoolName
        email
        createdAt
      }
    }`,
  variables: {
    input
  },
  authMode: "API_KEY",
});

export const finalizeMutation = (id, input) => API.graphql({
  query:
    `mutation finalize($id: ID, $input: FinalizeMemberApplicationInput!) {
      finalizeMemberApplication(id: $id, input: $input) {
        id
      }
    }`,
  variables: {
    id,
    input
  },
  authMode: "API_KEY",
});

export const createMartinServera = input => API.graphql({
  query:
    `mutation create($input: MartinServeraInput!) {
      createMartinServera(input: $input)
    }`,
  variables: {
    input
  },
  authMode: "API_KEY",  
});

export const createVegetableHouse = input => API.graphql({
  query:
    `mutation create($input: VegetableHouseInput!) {
      createVegetableHouse(input: $input)
    }`,
  variables: {
    input
  },
  authMode: "API_KEY",  
});