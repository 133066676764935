<template>
  <v-app>
    <v-app-bar app color="white" elevation="2">
      <div class="d-flex align-center">
        <v-img
          class="shrink ml-5"
          contain
          src="./assets/logo.svg"
          transition="scale-transition"
          width="110"
        />
      </div>
      <v-spacer></v-spacer>
      <v-icon>mdi-reply</v-icon>
      <a href="https://cmartsupport.se/gamed/" style="text-decoration:none"
        >Tillbaka till hemsidan</a
      >
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer color="blue" class="white--text">
      <v-row justify="space-between">
        <v-col cols="11"> © Cmart Support {{ new Date().getFullYear() }}</v-col>
        <v-col class="text-right">v.2.0</v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    //
  })
};
</script>
