<template>
  <v-container>
    <v-form v-model="formIsValid" ref="form">
      <v-row class="text-center">
        <v-col>
          <h1>
            Intresseanmälan
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!sent">
          <v-text-field v-model="name" label="Förskolans namn" :rules="[rules.required]" />
          <v-text-field v-model="businessContact" label="Kontaktperson" :rules="[rules.required]" />
          <v-text-field v-model="businessPhone" label="Telefonnummer"
              :rules="[rules.required, rules.phone]" />
          <v-text-field v-model="email" label="E-postadress" :rules="[rules.required, rules.email]" />
          
          <v-checkbox v-model="consent"
            label="Jag godkänner att denna webbplats lagrar min inlämnade information enligt beskrivningen i cforetaget.se/integritetspolicy så att de kan svara på min förfrågan."
            :rules="[rules.requiredBox]"></v-checkbox>
          <v-btn @click="save" color="blue" class="white--text" :disabled="sending">skicka</v-btn>
          <v-progress-circular class="ml-3" indeterminate v-if="sending" color="blue" size="25" />
          <p class="mt-3 red--text" v-if="error">
            Tekniskt fel uppstod, ansökan gick inte att skicka. Försök igen om
            en stund.
          </p>
        </v-col>
        <v-col v-else>
          <p>
            Din ansökan har skickats! Ett e-postmeddelande har skickats till
            <strong>{{ email }}</strong> med vidare instruktioner.
          </p>
          <p>Om du inte får meddelandet inom kort, kolla först och främst om det råkat hamna i skräpmappen.</p>
          <a href="https://cmartsupport.se/" style="text-decoration:none">Gå tillbaka till hemsidan</a>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { createMutation } from "../graphql";

export default {
  name: "InitialForm",

  data: () => ({
    name: null,
    email: null,
    businessContact: null,
    businessPhone: null,
    consent: false,
    sent: false,
    sending: false,
    error: false,
    formIsValid: false,
    rules: {
      required: value => !!value || "Måste anges",
      requiredBox: value => !!value || "Måste kryssas för",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Felaktigt format.";
      },
      phone: value => {
        const pattern = /^[0-9- ]+$/;
        return pattern.test(value) || "Använd endast siffror och bindestreck.";
      }
    }
  }),
  methods: {
    save() {
      this.error = false;
      if (!this.$refs.form.validate()) {
        console.log("form is invalid, aborting");
        return;
      }
      this.sending = true;
      console.log("saving...");
      createMutation({
        preschoolName: this.name,
        businessContact: this.businessContact,
        businessPhone: this.businessPhone,
        email: this.email,
        consent: this.consent
      })
        .then(data => {
          console.log("data from mutation", data);
          this.sending = false;
          this.sent = true;
        })
        .catch(err => {
          console.log("error saving application", err);
          this.sending = false;
          this.error = true;
        });
    }
  }
};
</script>
