import Vue from "vue";
import VueRouter from "vue-router";

import InitialForm from "./components/InitialForm.vue";
import Completion from "./components/Completion.vue";
import MartinServera from "./components/MartinServera.vue";
import VegetableHouse from "./components/VegetableHouse.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: InitialForm
  },
  {
    path: "/komplettering",
    component: Completion,
    props: true
  },
  {
    path: "/martinservera",
    component: MartinServera
  },
  {
    path: "/gronsakshuset",
    component: VegetableHouse
  }
];

let router = new VueRouter({
  routes,
  mode: "history"
});

export default router;
