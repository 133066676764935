<template>
  <v-container>
    <v-form v-model="formIsValid" ref="form">
      <v-row class="text-center">
        <v-col>
          <h1>
            {{
              isCompletion ? "Komplettera intresseanmälan" : "Intresseanmälan"
            }}
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!sent">
          <template v-if="!isCompletion">
            <v-text-field
              v-model="dataToSave.preschoolName"
              label="Verksamhetens namn"
              :rules="[rules.required]" />
            <v-text-field
              v-model="dataToSave.businessContact"
              label="Kontaktperson"
              :rules="[rules.required]" />
            <v-text-field
              v-model="dataToSave.businessPhone"
              label="Telefonnummer"
              :rules="[rules.required, rules.phone]" />
            <v-text-field
              v-model="dataToSave.email"
              label="E-postadress"
              :rules="[rules.required, rules.email]" />
          </template>
          <strong
            >Gäller
            {{ isCompletion ? "kompletteringen" : "intresseanmälan" }} en
            förskola eller ett familjedaghem?</strong
          >
          <v-select
            :items="items"
            label="Välj verksamhetstyp"
            v-model="dataToSave.type" />
          <v-text-field
            v-model="dataToSave.businessName"
            label="Verksamhetens/Bolagets juridiska namn"
            :rules="[rules.required]" />
          <v-text-field
            v-if="dataToSave.type === 'Familjedaghem'"
            v-model="daycareData.daycareName"
            label="Familjedaghemmets namn"
            :rules="[rules.required]" />
          <div v-if="dataToSave.type === 'Förskola'">
            <v-text-field
              v-model="preschoolData.preschoolAddress"
              label="Förskolans besöksadress"
              :rules="[rules.required]" />
            <v-text-field
              v-model="preschoolData.preschoolPostal"
              label="Förskolans postnummer"
              :rules="[rules.required, rules.numbers]" />
            <v-text-field
              v-model="preschoolData.preschoolPlace"
              label="Förskolans ort"
              :rules="[rules.required]" />
            <strong>Verksamhetens/Bolagets uppgifter</strong>
            <v-text-field
              v-model="preschoolData.businessNumber"
              label="Organisationsnummer"
              :rules="[rules.required, rules.orgnr]" />
            <v-text-field
              v-model="preschoolData.businessAddress"
              label="Bolagets adress"
              :rules="[rules.required]" />
            <v-text-field
              v-model="preschoolData.businessPostal"
              label="Bolagets postnummer"
              :rules="[rules.required, rules.numbers]" />
            <v-text-field
              v-model="preschoolData.businessPlace"
              label="Bolagets postort"
              :rules="[rules.required]" />
            <v-text-field
              v-model="preschoolData.businessEmail"
              label="E-postadress"
              :rules="[rules.required, rules.email]" />
            <v-text-field
              v-model="preschoolData.businessMobile"
              label="Mobiltelefon"
              :rules="[rules.required, rules.phone]" />
            <p>För att kunna handla hos Martin & Servera, fyll i formuläret som finns på följande länk och skicka in:
              <router-link to="/martinservera" target="_blank">Martin & Servera-anmälan</router-link>
            </p>
            <p>Har du redan ett konto hos Martin & Servera? Då behöver du bara fylla i ditt kundnummer nedan:</p>
            <v-text-field
              v-model="preschoolData.martinServeraCustomerId"
              label="Martin & Servera-kundnummer" />
          </div>
          <v-text-field
            type="number"
            v-model="dataToSave.numberOfChildren"
            label="Antal barn i verksamheten"
            :rules="[rules.required]" />
          <v-textarea v-model="dataToSave.businessMisc" label="Övrigt" />
          <strong>Leveransadress</strong>
          <v-text-field
            v-model="dataToSave.daycareAddress"
            label="Adress"
            :rules="[rules.required]" />
          <v-text-field
            v-model="dataToSave.daycarePostal"
            label="Postnummer"
            :rules="[rules.required, rules.numbers]" />
          <v-text-field
            v-model="dataToSave.daycarePlace"
            label="Ort"
            :rules="[rules.required]" />
          <strong>Fakturaadress</strong>
          <v-text-field
            v-model="dataToSave.invoiceAddress"
            label="Fakturaadress (om annan än leveransadress)" />
          <v-text-field
            v-model="dataToSave.invoiceEmail"
            label="E-postadress för fakturautskick"
            :rules="[rules.required, rules.email]" />
          <p class="mt-7">
            Om du har ett IKEA-kort så kan du ansluta det till oss och på så
            sätt ta del av erbjudanden, avtal och priser från IKEA. Vi
            vidarebefordrar ditt kundnummer till Ikano Bank för att möjliggöra
            det. Fyll i ditt kundnummer nedan om du vill att vi gör det:
          </p>
          <v-text-field
            v-model="dataToSave.IKEACustomerId"
            label="IKEA-kundnummer" />
          <p class="mt-5">
            Om du inte har ett IKEA-kort så kan du ansöka om det här:
            <a
              href="https://ikea-business.web.verified.eu/?source=C_Resurs"
              target="_blank"
              >https://ikea-business.web.verified.eu/?source=C_Resurs</a
            ><br />
            Du blir då automatiskt ansluten.
          </p>
          <strong>GDPR</strong>
          <v-checkbox
            v-model="consent"
            label="Jag godkänner att denna webbplats lagrar min inlämnade information enligt beskrivningen i cforetaget.se/integritetspolicy så att de kan svara på min förfrågan."
            :rules="[rules.requiredBox]"></v-checkbox>
          <v-btn
            @click="save"
            color="blue"
            :disabled="sending"
            class="white--text"
            >skicka {{ isCompletion? "komplettering" : "anmälan" }}</v-btn
          >
          <v-progress-circular
            class="ml-3"
            indeterminate
            v-if="sending"
            color="blue"
            size="25" />
          <p class="mt-3 red--text" v-if="error">
            Tekniskt fel uppstod, {{ isCompletion ? "kompletteringen" : "anmälan" }} gick inte att skicka. Försök igen om en stund.
          </p>
        </v-col>
        <v-col v-else>
          <p>
            Din {{ isCompletion ? "komplettering" : "anmälan" }} har skickats! Ett e-postmeddelande med mer information har skickats till den e-postadress du angav{{ isCompletion ? " tidigare" : "" }}.
          </p>
          <a
            href="https://cmartsupport.se/"
            style="text-decoration: none"
            >Gå tillbaka till hemsidan</a
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { finalizeMutation } from "../graphql";

export default {
  name: "Completion",

  data: () => ({
    items: ["Förskola", "Familjedaghem"],
    dataToSave: {
      email: null,
      preschoolName: null,
      businessContact: null,
      businessPhone: null,
      type: null,
      businessName: null,
      numberOfChildren: 0,
      businessMisc: null,
      daycareAddress: null,
      daycarePostal: null,
      daycarePlace: null,
      IKEACustomerId: null,
      invoiceAddress: null,
      invoiceEmail: null
    },
    daycareData: {
      daycareName: null
    },
    preschoolData: {
      preschoolAddress: null,
      preschoolPostal: null,
      preschoolPlace: null,
      businessNumber: null,
      businessAddress: null,
      businessPostal: null,
      businessPlace: null,
      businessEmail: null,
      businessMobile: null,
      martinServeraCustomerId: null
    },
    consent: false,
    sending: false,
    sent: false,
    error: false,
    formIsValid: false,
    isCompletion: true,
    rules: {
      required: value => !!value || "Måste anges",
      requiredBox: value => !!value || "Måste kryssas för",
      numbers: value => {
        const pattern = /^[0-9 ]+$/;
        return pattern.test(value) || "Använd endast siffror.";
      },
      orgnr: value => {
        const pattern = /^[0-9-]+$/;
        return pattern.test(value) || "Använd endast siffror och bindestreck.";
      },
      phone: value => {
        const pattern = /^[0-9- ]+$/;
        return pattern.test(value) || "Använd endast siffror och bindestreck.";
      },
      email: value => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Felaktigt format.";
      }
    }
  }),
  mounted() {
    if (!this.$route.query.id) this.isCompletion = false;
  },
  methods: {
    save() {
      this.error = false;
      if (!this.$refs.form.validate()) {
        console.log("form is invalid, aborting");
        return;
      }
      this.sending = true;
      console.log("saving...");
      let update;
      if (this.dataToSave.type === "Familjedaghem") {
        update = Object.assign(this.dataToSave, this.daycareData);
      } else {
        update = Object.assign(this.dataToSave, this.preschoolData);
      }
      finalizeMutation(this.$route.query.id, {
        ...update
      })
        .then(result => {
          if (result.errors) {
            throw new Error(result.errors);
          }
          console.log("saved!");
          this.sent = true;
        })
        .catch(error => {
          console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.sending = false;
        });
    }
  }
};
</script>
