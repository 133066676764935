<template>
  <v-container>
    <v-form v-model="formIsValid" ref="form">
      <v-row class="text-center">
        <v-col>
          <h1>
            Martin & Servera kundanmälan
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!sent">
          <v-text-field v-model="departmentName" label="Enhetsnamn (arbetsställe)" :rules="[rules.required]" />
          <v-text-field v-model="businessName" label="Reg. Bolagsnamn" :rules="[rules.required]" />
          <v-text-field v-model="legalOwnerNumber" label="Juridisk ägare/Org.nummer"
              :rules="[rules.required]" />
          
          <strong>Leveransadress</strong>
          <v-text-field v-model="deliveryAddress" label="Gatuadress" :rules="[rules.required]" />
          <v-text-field v-model="deliveryPostal" label="Postnummer" :rules="[rules.required]" />
          <v-text-field v-model="deliveryPlace" label="Ort" :rules="[rules.required]" />
          <v-text-field v-model="deliveryPlaceInfo" label="Info om leveransplats" />
          <v-text-field v-model="deliveryAddressNote" label="Not. om leveransadress" />

          <strong>Faktureringsadress</strong>
          <v-text-field v-model="invoiceAddress" label="Postadress/Box" :rules="[rules.required]" />
          <v-text-field v-model="invoicePostal" label="Postnummer" :rules="[rules.required]" />
          <v-text-field v-model="invoicePlace" label="Ort" :rules="[rules.required]" />
          <v-text-field v-model="GLNInvoice" label="GLN Faktura" />
          <v-text-field v-model="invoiceEmail" label="E-postadress för faktura" :rules="[rules.required, rules.email]" />
          <v-text-field v-model="KSTRef" label="KST/Referens" />

          <strong>Kontaktuppgifter (arbetsställe)</strong>
          <v-text-field v-model="departmentContactName" label="Namn" :rules="[rules.required]" />
          <v-text-field v-model="departmentContactPhoneOffice" label="Telefonnummer (kontor)" :rules="[rules.required, rules.phone]" />
          <v-text-field v-model="departmentContactPhoneOther" label="Telefonnummer (övrigt)" :rules="[rules.required, rules.phone]" />
          <v-text-field v-model="departmentContactEmail" label="E-postadress" :rules="[rules.required, rules.email]" />
          <v-text-field v-model="departmentContactRole" label="Roll" :rules="[rules.required]" />

          <strong>E-handel beställare</strong>
          <v-text-field v-model="onlineShopperName" label="Namn" :rules="[rules.required]" />
          <v-text-field v-model="onlineShopperEmail" label="E-postadress" :rules="[rules.required, rules.email]" />

          <strong>Önskade leveransdagar</strong>
          <!-- list of checkboxes, one for each weekday, with a text field next to it -->
          <template v-for="day in deliveryDays">
            <v-checkbox v-model="day.chosen" :label="day.name"></v-checkbox>
            <v-text-field v-model="day.time" label="Önskad tid" :disabled="!day.chosen" />
          </template>
          
          <v-checkbox v-model="consent"
            label="Jag godkänner att denna webbplats lagrar min inlämnade information enligt beskrivningen i cforetaget.se/integritetspolicy så att de kan svara på min förfrågan."
            :rules="[rules.requiredBox]"></v-checkbox>
          <v-btn @click="save" color="blue" class="white--text" :disabled="sending">skicka</v-btn>
          <v-progress-circular class="ml-3" indeterminate v-if="sending" color="blue" size="25" />
          <p class="mt-3 red--text" v-if="error">
            Tekniskt fel uppstod, ansökan gick inte att skicka. Försök igen om
            en stund.
          </p>
        </v-col>
        <v-col v-else>
          <p>
            Tack för din ansökan!
          </p>
          <a href="https://cmartsupport.se/" style="text-decoration:none">Gå tillbaka till hemsidan</a>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { createMartinServera } from "../graphql";

export default {
  name: "MartinServera",

  data: () => ({
    departmentName: null,
    businessName: null,
    legalOwnerNumber: null,
    deliveryAddress: null,
    deliveryAddressNote: null,
    deliveryPostal: null,
    deliveryPlace: null,
    deliveryPlaceInfo: null,
    invoiceAddress: null,
    invoicePostal: null,
    invoicePlace: null,
    GLNInvoice: null,
    invoiceEmail: null,
    KSTRef: null,
    departmentContactName: null,
    departmentContactPhoneOffice: null,
    departmentContactPhoneOther: null,
    departmentContactEmail: null,
    departmentContactRole: null,
    onlineShopperName: null,
    onlineShopperEmail: null,
    deliveryDays: [
      {
        chosen: false,
        name: "Måndag",
        time: null
      },
      {
        chosen: false,
        name: "Tisdag",
        time: null
      },
      {
        chosen: false,
        name: "Onsdag",
        time: null
      },
      {
        chosen: false,
        name: "Torsdag",
        time: null
      },
      {
        chosen: false,
        name: "Fredag",
        time: null
      },
    ],
    consent: false,
    sent: false,
    sending: false,
    error: false,
    formIsValid: false,
    rules: {
      required: value => !!value || "Måste anges",
      requiredBox: value => !!value || "Måste kryssas för",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Felaktigt format.";
      },
      phone: value => {
        const pattern = /^[0-9- ]+$/;
        return pattern.test(value) || "Använd endast siffror och bindestreck.";
      }
    }
  }),
  methods: {
    save() {
      this.error = false;
      if (!this.$refs.form.validate()) {
        console.log("form is invalid, aborting");
        return;
      }
      this.sending = true;
      console.log("saving...");
      let desiredDeliveryDays = "";
      this.deliveryDays.forEach(day => {
        if (day.chosen) {
          desiredDeliveryDays += `${day.name} ${day.time}#`;
        }
      });
      createMartinServera({
        departmentName: this.departmentName,
        businessName: this.businessName,
        legalOwnerNumber: this.legalOwnerNumber,
        deliveryAddress: this.deliveryAddress,
        deliveryAddressNote: this.deliveryAddressNote,
        deliveryPostal: this.deliveryPostal,
        deliveryPlace: this.deliveryPlace,
        deliveryPlaceInfo: this.deliveryPlaceInfo,
        invoiceAddress: this.invoiceAddress,
        invoicePostal: this.invoicePostal,
        invoicePlace: this.invoicePlace,
        GLNInvoice: this.GLNInvoice,
        invoiceEmail: this.invoiceEmail,
        KSTRef: this.KSTRef,
        departmentContactName: this.departmentContactName,
        departmentContactPhoneOffice: this.departmentContactPhoneOffice,
        departmentContactPhoneOther: this.departmentContactPhoneOther,
        departmentContactEmail: this.departmentContactEmail,
        departmentContactRole: this.departmentContactRole,
        onlineShopperName: this.onlineShopperName,
        onlineShopperEmail: this.onlineShopperEmail,
        desiredDeliveryDays
      })
        .then(data => {
          console.log("data from mutation", data);
          this.sending = false;
          this.sent = true;
        })
        .catch(err => {
          console.log("error saving application", err);
          this.sending = false;
          this.error = true;
        });
    }
  }
};
</script>
